.topbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  color: white;
}

.topbar_left {
  display: flex;
  align-items: center;
  color: #A9A9A9;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.1rem;
  transition: transform 0.3s ease, color 0.3s ease;
  z-index: 1001;

  > img {
    width: 20rem;
    height: auto;
    transition: transform 0.3s ease, opacity 0.3s ease;
    -webkit-user-drag: none;

    &:hover {
      transform: scale(0.99);
      opacity: 0.7;
    }
  }


  &:hover{
    color: white;
    cursor: pointer;
  }
}

.topbar_menu {
  display: flex;
  flex: 1;
  justify-content: left;
  margin-left: 3rem;
  gap: 25px;
}

.topbar_menu_open{
  display: flex;
  flex-direction: column;

}

.topbar_menu_item_open{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.5rem;
  border-top: 1px solid rgba(128, 128, 128, 0.5);
  border-bottom: 1px solid rgba(128, 128, 128, 0.5);
  cursor: pointer;

  > svg{
    width: 1.2rem;
  }
}

.topbar_open {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #15161d;
  z-index: 2000;
}

.topbar_right{
  display: flex;
  align-items: center;
  gap: 1rem;

  > .container_steam{
    background-color: #f4bf58;
    display: flex;
    align-items: center;
    height: 55px;
    padding: 0 2rem;
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    color: black;
    font-weight: 700;
    cursor: not-allowed;

    > svg{
      width: 26px;
      height: 26px;
      fill: black;
      margin-right: 1rem;
      transition: transform 0.6s ease;
    }
    &:hover {
      filter: brightness(1.1);
      > svg {
        transform: rotate(360deg);
      }
    }
  }
  > .container_settings{
    background-color: #202127;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 43px;
    width: 43px;
    border-radius: 10px;
    cursor: pointer;
    cursor: not-allowed;

    > svg{
      fill: #9b9db1;
      width: 26px;
      height: 26px;

    }
    &:hover {
      filter: brightness(1.1);
      > svg {
        animation: rotate-animation 0.5s ease-in-out;
      }
    }
  }
}

@keyframes rotate-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}


.loginButton {
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.themeIcon {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;

  justify-content: center;
  align-items: end;
}

.line {
  width: 40px;
  height: 6px;
  background-color: white;
  border-radius: 4px;
}

.cross {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
}

.cross .line {
  position: absolute;
}

.cross > div:first-child {
  transform: rotate(45deg);
}

.cross > div:last-child {
  transform: rotate(-45deg);
}

.news_icon{
  width: 22px;
  height: auto;
  fill: #9b9db1;
}

.news_container{
  display: flex;
  justify-content: center;
  align-items: start;
  position: relative;
  padding-bottom: 7px;
  padding-right: 7px;
  &:hover{
    cursor: pointer;
    > svg{
      fill: #f4bf58;
    }
  }
}
.news_badge {
  font-family: "Inter", serif;
  line-height: 1;
  position: absolute;
  top: -5px;
  right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  background-color: red;
  color: white;
  font-size: 9px;
  font-weight: bold;
  text-align: center;
}


@media (max-width: 1450px) {
  .topbar_left {
    > img{
      width: 18rem;
    }
  }
  .topbar_menu {
    margin: 1.4rem;
    gap: 15px;
  }
  .topbar_right{
    gap: 0.7rem;
    > .container_steam{
      padding: 0 1.8rem;
      height: 50px;
    }
    > .container_settings{
      height: 40px;
      width: 40px;

      >svg{
        width: 24px;
        height: 24px;
      }
    }
  }
}


@media (max-width: 1200px) {
  .topbar_left {
    > img{
      width: 5rem;
    }
  }
  .topbar_menu {
    margin: 1.2rem;
    gap: 15px;
  }
  .topbar_right{
    gap: 0.4rem;
    > .container_steam{
      //display: flex;
      //justify-content: center;
      //align-items: center;
      padding: 0 1.5rem;
      height: 45px;
      //width: 40px;
      >svg{
        //margin-right: 0;
      }
      >div{
        //display: none;
      }
    }
    > .container_settings{
      height: 38px;
      width: 38px;

      >svg{
        width: 22px;
        height: 22px;
      }
    }
  }
}


@media (max-width: 900px) {
  .topbar_left {
    > img{
      width: 5rem;
    }
  }

  .topbar_menu {
    justify-content: center;
    margin: 0;
  }

  .hamburger{
    display: flex;
  }

  .topbar_right{
    display: none;
  }
}


