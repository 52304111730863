.policy_container {
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333;
  line-height: 1.6;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.policy_container p {
  margin-bottom: 10px;
}
.policy_container ul {
  padding-left: 20px;
}
.policy_container li {
  margin-bottom: 5px;
  list-style-type: disc;
}
