.div{
  background-color: #181920;
  max-width: 1600px;
  margin: 0 auto;
  font-family: "Inter", serif;
}

.table{
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
  font-family: "Inter", serif;
  padding: 1.5rem;
  table-layout: fixed;
}

.table th:first-child,
.table td:first-child{
  width:110px;
  white-space: nowrap;
  text-align: center;
}
.table th:first-child{
  padding: 16px;
}
.table td:first-child{
  padding: 5px;
}

.table th:last-child,
.table td:last-child{
  width:95px;
  padding: 13px;
  white-space: nowrap;
  text-align: center;
}

.table th:nth-child(2),
.table td:nth-child(2),
.table th:nth-child(3),
.table td:nth-child(3) {
  width: calc((100% - 205px) / 2);
  overflow: hidden;
  text-overflow: ellipsis;
}
.table th:nth-child(3),
.table td:nth-child(3){
  padding: 12px 0 12px 12px;
}

.table th:nth-child(2),
.table td:nth-child(2){
  padding: 12px 12px 12px 0;
}

.points {
  position: relative;
  color: #ffa071 !important;
  overflow: hidden;
  font-size: 1.05rem !important;
  > div {
    background: rgba(255, 160, 113, .29);
    border-radius: 10px;
    padding: 0.2rem 0;
  }
}

.points_group {
  position: relative;
  color: #ffa071 !important;
  overflow: hidden;
  font-size: 0.8rem !important;
  > div {
    background: rgba(255, 160, 113, .29);
    border-radius: 10px;
    padding: 0.1rem 0;
    margin: 0 0.4rem;
  }
}

.table th{
  color: #f4bf58;
  font-size: 1.5rem;
  background-color: #16171d;
}

.table th:first-child, .table_group th:first-child{
  border-top-left-radius: 5px;
}
.table th:last-child, .table_group th:last-child{
  border-top-right-radius: 5px;
}

.table td:first-child, .table_group td:first-child{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.table td:last-child, .table_group td:last-child{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.table td{
  color: #9b9db1;
  font-size: 1.4rem;
  font-weight: 500;
  background-color: #1c1d24;
}

.container_table_data{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.playoff_team {
  box-shadow: 0px 0px 3px rgba(0, 255, 127, 0.7);
  border-radius: 5px;
}

.no_playoff_team {
  box-shadow: 0px 0px 3px rgba(255, 0, 0, 0.7);
  border-radius: 5px;
}

.wreathContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wreathIcon {
  width: 65px;
  height: auto;
  margin-top: 3px;
  opacity: 0.4;
}

.wreathIconGroup{
  width: 36px;
  height: auto;
  margin-top: 3px;
  opacity: 0.4;
}

.rankNumber {
  position: absolute;
  font-size: 17px;
  font-weight: bold;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.rankNumberGroup {
  position: absolute;
  font-size: 0.95rem;
  font-weight: bold;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}


.table_info{
  box-shadow: 0px 0px 3px rgba(244, 191, 88, 0.7);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.id{
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;

  &:hover{
    color: #f4bf58;
    transform: scale(1.1);
  }
}
.active {
  color: #f4bf58;
}

.avatar{
  width: 48px;
  height: 48px;
  border-radius: 8px;
}

.avatarGroup{
  width: 34px;
  height: 34px;
  border-radius: 8px;
}

.player_container{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
}

.player_container_group{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}

.table_group{
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
  font-family: "Inter", serif;
  padding: 0.5rem 1rem 1rem 1rem;
  table-layout: fixed;
  max-width: 700px;
}

.table_group th:first-child,
.table_group td:first-child {
  width: 50px;
  white-space: nowrap;
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: center;
}
.table_group th:last-child,
.table_group td:last-child {
  width: 60px;
  white-space: nowrap;
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: center;
}

.table_group th:nth-child(2),
.table_group td:nth-child(2),
.table_group th:nth-child(3),
.table_group td:nth-child(3) {
  width: calc((100% - 145px) / 2);
  overflow: hidden;
  text-overflow: ellipsis;
}

.table_group th:nth-child(2),
.table_group td:nth-child(2){
  padding: 6px 6px 6px 4px;
}

.table_group th:nth-child(3),
.table_group td:nth-child(3){
  padding: 6px 4px 6px 6px;
}

.table_group td{
  color: #9b9db1;
  font-size: 0.9rem;
  font-weight: 500;
  background-color: #1c1d24;
}

.table_group th{
  color: #f4bf58;
  font-size: 1rem;
  background-color: #16171d;
}

.groups_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
}

.group_container{
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
}

.container_buttons_map{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 55px 0;
  border-radius: 10px 10px 0 0;
  gap: 15px;
  max-width: 700px;
  margin: 0 auto;
}

.button {
  padding: 0.6rem 0;
  width: 100%;
  border-radius: 10px;
  font-family: "Inter", serif;
  font-size: 1.2rem;
  background-color: #f3ca80;
  color: #1a2330;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
  line-height: 24px;

  &:hover{
    background-color: #f2d18f;
  }

  &.active {
    cursor: default;
    background-color: #ab8948;
  }
}
.group_name{
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
}

.maps_name{
  color: #f4bf58;
  font-size: 2.2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5rem;
}

.noGroupsMessage{
  color: white;
  font-weight: bold;
  font-size: 2.2rem;
  padding-bottom: 4rem;
  text-align: center;
}


