.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 46px 26px 26px 26px;
  text-align: center;
  background-color: #15161d;
  color: white;
  margin-top: 5rem;
}
.footer_logo{
  display: flex;
  > img{
    width: 20rem;
    height: auto;
  }
}

.email_container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #f4bf58;
  border-radius: 10px;
  width: 100%;
  padding: 0 4px;
  height: 60px;
  transition: background-color 0.3s ease;

  &:hover {
    cursor: pointer;
    background-color: #f4bf58;
  }

  &:hover > div:last-child {
    color: black;
    transition: color 0.3s ease;
  }

  > svg {
    width: 3rem;
    height: auto;
    fill: #f4bf58;
    margin-right: 0.5rem;
    transition: fill 0.3s ease;
  }

  &:hover svg {
    fill: black;
  }

  &:hover .icon1 {
    display: none;
  }

  &:hover .icon2 {
    display: flex;
  }
}

.icon2{
  display: none;
}

.discord_container{
  display: flex;
  width: auto;
  height: 60px;
  padding: 13px;
  background: none;
  border-radius: 10px;
  border: 3px solid #f4bf58;
  transition: background-color 0.3s ease;

  &:hover{
    cursor: pointer;
    background-color: #f4bf58;

    >svg{
      fill: black;
    }
  }
}

.discord{
  width: 100%;
  height: auto;
  fill: #f4bf58;
  transition: fill 0.3s ease;
}

.container_icons{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1.5rem 0;
}

.text{
  font-size: 1.1rem;
  font-family: "Inter", serif;
  color: #9b9db1;
  font-weight: 600;

}

