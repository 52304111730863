.abilitiesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.7rem;
  margin-top: 1rem;
}

.ability {
  position: relative;
  width: 70px;
  display: flex;
  text-align: center;
}

.abilityImage {
  width: 100%;
  cursor: pointer;
}

.abilityDetails {
  position: absolute;
  top: 80%;
  left: 100%;
  width: 300px;
  background: linear-gradient(150deg, #253137, #12181C);
  color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  visibility: hidden;
  transform: translate(10px, -50%);
  z-index: 12;
  white-space: normal;
  pointer-events: none;
}




.ability:hover .abilityDetails {
  visibility: visible;
}


.highlightedValue {
  font-weight: bold;
  color: #ff9900;
  background-color: rgba(255, 255, 0, 0.2);
  padding: 2px 4px;
  border-radius: 3px;
}

.abilityVideo {
  width: 100%;
  max-width: 400px;
}

.ability_content{
  padding: 10px;
  text-align: left;
}

.ability_title{
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 1.2;
  font-family: "Inter", serif;
}



.ability_description{
  color: #acb8c7;
  font-family: 'Open Sans', sans-serif;
  margin-top: 3px;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 1.3;
}

@media (max-width: 1600px) {
  .ability {
    width: 60px;
  }

  .abilityDetails {
    width: 225px; /* 300px - 25% */
    top: 60%; /* 80% - 25% */
    transform: translate(7.5px, -50%); /* 10px - 25% */
  }

  .abilityVideo {
    max-width: 300px; /* 400px - 25% */
  }

  .ability_content {
    padding: 7.5px; /* 10px - 25% */
  }

  .ability_title {
    font-size: 1.275rem; /* 1.7rem - 25% */
  }

  .ability_description {
    font-size: 12.75px; /* 17px - 25% */
    margin-top: 2.25px; /* 3px - 25% */
  }
}

@media (max-width: 1000px) {
  .abilityDetails {
    top: auto;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-10px);
  }

  .abilitiesContainer{
    justify-content: center;
    padding: 0 4rem;
  }
}
