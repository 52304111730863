.menuItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  color: #9b9db1;
  gap: 7px;

  > svg {
    width: 22px;
    height: auto;
    fill: #9b9db1;
    transition: fill 0.3s ease;
  }

  &:hover {
    color: #f4bf58;

    > svg {
      fill: #f4bf58;
      animation: rotate-animation 0.5s ease-in-out;
    }
  }
}

@keyframes rotate-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.active {
  color: #f4bf58;

  > svg {
    fill: #f4bf58;
  }
}

//.icon {
//  margin-right: 0.5rem;
//}

.title {
  font-weight: 700;
  font-family: "Inter", serif;
  font-size: 18px;
}

@media (max-width: 1200px) {
  .title {
    font-size: 16px;
  }

  .menuItem{
    gap: 6px;
    > svg{
      width: 21px;
    }
  }
}

@media (max-width: 900px) {
  .title {
    font-size: 23px;
  }
  .menuItem{
    display: none;
  }
  .active {
    display: flex;
    margin-top: 3px;
  }
  .menuItem.open {
    display: flex;
  }
}

@media (max-width: 700px) {
  .title {
    font-size: 17px;
  }
  .menuItem{
    > svg{
      width: 17px;
    }
  }
}
