.div{
  text-align: center;
  font-family: "Inter", serif;
}

.main_text{
  margin-top: 3rem;
  font-size: 3rem;
  font-weight: bold;
  color: white;
}

.text{
  font-size: 1.5rem;
  color: #f4bf58;
}

@media (max-width: 1200px) {
  .main_text{
    font-size: 2.3rem;
  }

  .text{
    font-size: 1.2rem;
  }
}

@media (max-width: 700px) {
  .main_text{
    font-size: 1.7rem;
  }

  .text{
    font-size: 0.9rem;
  }
}