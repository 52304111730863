

.div{
  //background-color: yellow;
  width: 100%;
  height: 100%;

  > .div_hero{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    border-radius: 15px;
    > .div_name_hero{
      color: white;
      font-size: 2rem;
      font-weight: bold;
      margin: 1rem;
    }
    > .div_hero_talents{
      margin-bottom: 1rem;
    }
  }
}
.div_container_sides{
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > .div_text{
    height: 100%;
  }
}






.heroSceneContainer {
  position: relative;
}

.block_name {
  display: flex;
  align-items: center;
  gap: 5px;
}

.text_name_hero {
  font-weight: 500;
  font-size: 38px;
  font-family: "Inter", serif;
  color: #fff;
}

.block_name img {
  width: 42px;
  height: 42px;
}

.overlayBlock {
  position: absolute;
  top: 0;
  left: 3%;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1;
  margin-top: 50px;
}

.overlayText {
  color: white;
  font-family: "Inter", serif;
  font-weight: bold;
  text-align: left;
  font-size: 79px;
  line-height: 0.9;
}


.abilitiesContainer {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 1600px) {
  .text_name_hero {
    font-size: 18.75px; /* 24px - 25% */
  }

  .block_name img {
    width: 26.5px; /* 34px - 25% */
    height: 26.5px;
  }

  .overlayBlock {
    max-width: 429px; /* 550px - 25% */
    margin-top: 38px; /* 50px - 25% */
  }

  .overlayText {
    font-size: 59px; /* 76px - 25% */
    line-height: 0.9;
  }
}

@media (max-width: 1000px) {
  .overlayBlock {
    position: sticky;
    display: flex;
    align-items: center;
    margin-top: 0;
    padding-top: 20px;
    max-width: 100%;
    width: auto;
    background-color: #0a0b10;
  }

  .overlayText {
    font-size: 40px; /* уменьшаем размер шрифта */
    line-height: 1.2; /* немного увеличиваем межстрочный интервал */
  }
}

.render_talents_container{
  position: relative;
  background: linear-gradient(to bottom, #0a0b10 , #12131a 50%);
  z-index: 3;
}






//.ability {
//  position: relative;
//  width: 60px;
//  text-align: center;
//}
//
//.abilityImage {
//  width: 100%;
//  cursor: pointer;
//}
//
//.abilityDetails {
//  position: absolute;
//  top: 100%;
//  left: 50%;
//  width: 250px;
//  background: rgba(0, 0, 0, 0.8);
//  color: white;
//  padding: 10px;
//  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
//  visibility: hidden;
//  transform: translate(-50%, 10px);
//  z-index: 12;
//}
//
//.ability:hover .abilityDetails {
//  visibility: visible;
//}
//
//.highlightedValue {
//  font-weight: bold;
//  color: #ff9900; /* Оранжевый цвет */
//  background-color: rgba(255, 255, 0, 0.2); /* Полупрозрачный жёлтый фон */
//  padding: 2px 4px;
//  border-radius: 3px;
//}
//
//.abilityVideo {
//  width: 100%;
//  max-width: 400px; /* Максимальная ширина видео */
//  margin-bottom: 10px; /* Отступ снизу */
//  border-radius: 8px; /* Закругление углов */
//}
//
//
//
//
//.grayscale {
//  filter: grayscale(100%);
//}
//
//
//
///* Общий контейнер */
//.render2Container {
//  display: flex;
//  flex-direction: row; /* Теперь размещаем элементы по горизонтали */
//  gap: 30px; /* Расстояние между левой и правой частью */
//  justify-content: center; /* Центрируем содержимое по горизонтали */
//  align-items: flex-start; /* Выровнять по верху */
//  padding: 0 2rem;
//  width: 100%;
//
//}
//
//.render2AbilityWrapper {
//  display: flex;
//  flex-direction: column;
//  gap: 20px;
//  align-items: center;
//  max-width: 800px;
//  width: 100%;
//  position: relative;
//}
//
//.render2VideoWrapper {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.5);
//}
//
//.blackBackground {
//  background-color: black;
//  width: 800px;
//  height: 450px;
//  color: #f4bf58;
//  font-family: "Inter", serif;
//  font-weight: bold;
//  font-size: 36px;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//}
//
///* Стили для видео */
//.render2videoElement {
//  max-width: 100%;
//  height: auto;
//  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Лёгкая тень для видео */
//}
//
//.render2AbilitiesList {
//  display: flex;
//  flex-wrap: wrap;
//  gap: 15px;
//  justify-content: center;
//  position: absolute;
//  top: 97%;
//}
//
//.render2ImageWrapper {
//  width: 75px;
//  height: 75px;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  overflow: hidden;
//  cursor: pointer;
//  box-shadow: 0 0 10px #000;
//  transition: transform 0.3s ease;
//  position: relative;
//
//}
//
//
//.render2ImageWrapper:hover {
//  transform: scale(1.1);
//}
//
//.render2ImageSmall {
//  width: 100%;
//  height: 100%;
//  object-fit: cover;
//}
//
//.render2ImageWrapper span {
//  color: #888;
//  font-size: 14px;
//}
//
//.render2AbilityInfo {
//  display: flex;
//  flex-direction: column;
//  gap: 10px;
//  max-width: 650px;
//  width: 100%;
//}
//
//.render2AbilityName {
//  font-size: 24px;
//  font-weight: bold;
//  color: white; /* Цвет текста */
//  margin: 0;
//  font-family: "Inter", serif;
//  line-height: 1;
//}
//
//.render2AbilityDescription {
//  font-size: 14px;
//  color: white;
//  font-family: "Inter", serif;
//  line-height: 1.3;
//  font-weight: 400;
//}
//
//.render2AbilityInfoContainer{
//  display: flex;
//  justify-content: start;
//  gap: 0.5rem;
//  background-color: #121212;
//  padding: 0.5rem;
//
//  > img{
//    width: 95px;
//    height: 95px;
//    margin: 10px;
//    box-shadow: 0 0 10px #000;
//  }
//
//  > div{
//    display: flex;
//    flex-direction: column;
//    gap: 0.3rem;
//    padding-top: 0.5rem;
//  }
//}
//
//.render2AbilityValuesContainer{
//  background-color: #000;
//  color: white;
//  padding: 20px 30px 15px 30px;
//
//  > div:first-child{
//    line-height: 1.45;
//  }
//}
//
//.abilityDescription, .abilityValue{
//  font-size: 14px;
//  font-family: "Inter", serif;
//}
//.abilityDescription{
//  color: #737373;
//  text-transform: uppercase;
//  letter-spacing: 1px;
//  font-weight: 300;
//}
//
//.abilityValue{
//  color: #fff;
//  font-weight: 600;
//  letter-spacing: 0;
//  text-transform: none;
//  margin-left: 0.3rem;
//}
//
//.mana_icon{
//  width: 16px;
//  height: 16px;
//  border-radius: 3px;
//  background: linear-gradient(#00A4DB, #007196);
//}
//
//.render2FieldItem{
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  font-family: "Inter", serif;
//  color: white;
//  gap: 0.4rem;
//  font-size: 13px;
//  font-weight: 400;
//
//  > img{
//    width: 16px;
//    height: 16px;
//  }
//}
//
//.render2AbilityFields{
//  display: flex;
//  align-items: center;
//  justify-content: start;
//  gap: 2rem;
//  margin-top: 12px;
//}
//
//.lore_container{
//  margin-top: 20px;
//  margin-bottom: 10px;
//  font-style: italic;
//  font-size: 12px;
//  color: #aaa;
//  background-color: #111;
//  padding: 7px;
//}
//
//.noFoundAbilities{
//  padding-top: 8rem;
//  color: #f4bf58;
//  text-align: center;
//  font-weight: 400;
//  font-family: "Inter", serif;
//  font-size: 20px;
//}










